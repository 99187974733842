import { Theme } from "styled-system";

const baseColors = {
  blue: "#040053",
  purple: "#440bd4",
  salmon: "#ff2079",
  pink: "#e92efb",
};

export const colors = {
  text: baseColors.salmon,
  background: baseColors.blue,
  primary: baseColors.salmon,
  accent: baseColors.pink,
  ...baseColors,
};

export const theme: Theme = {
  colors,
  space: [0, 4, 8, 16, 24, 32, 64, 128, 256, 512],
  fontSizes: [
    "0.823em",
    "0.878em",
    "0.937em",
    "1em",
    "1.067em",
    "1.138em",
    "1.215em",
    "1.296em",
    "1.383em",
  ],
  breakpoints: ["40em", "52em", "64em"],
};
