import * as React from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import css from "@styled-system/css";
import { theme, colors } from "../styles";

const GlobalStyle = createGlobalStyle(
  css({
    "*": {
      boxSizing: "border-box",
    },
    html: {
      fontSize: "16px",
    },
    body: {
      m: 0,
      p: 0,
      color: "text",
      backgroundColor: "background",
      fontWeight: 400,
      lineHeight: 1.45,
      fontFamily: `'Source Code Pro', monospace`,
    },
    p: {
      mb: "1.25em",
    },
    "h1, h2, h3, h4, h5": {
      m: "2.75rem 0 1rem",
      fontWeight: 400,
      lineHeight: 1.15,
    },
    h1: {
      mt: 0,
      fontSize: 8,
      textShadow: `0 0 2px ${colors.accent}, 0 0 5px ${colors.accent}`,
    },
    h2: { fontSize: 7 },
    h3: { fontSize: 6 },
    h4: { fontSize: 5 },
    h5: { fontSize: 4 },
    "small, .text_small": { fontSize: 2 },
  }),
);

const Layout: React.FC = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />
      {children}
    </>
  </ThemeProvider>
);

export default Layout;
